import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SpinnerCircularSplit } from 'spinners-react'
import Swal from 'sweetalert2'
import logo from '../dist/safepay-logo.png'
import { confirm_transaction } from '../modules/transaction.module'

const TransConfirmation = () => {
  const navigate = useNavigate()

  const [params] = useSearchParams()

  const status = params?.get('status')
  const trans_id = params?.get('id')


  useEffect(() => {
    if (status === 'failed') {
       Swal.fire({
         icon: 'error',
         text: 'Payment was not successful!',
         showConfirmButton: true,
         showCloseButton: false,
       }).then((result) => {
         if (result.isConfirmed) window.location.replace('/')
       })
    } else {
      confirm_transaction(trans_id)
        .then((data) => {
          if (data.status) {
            Swal.fire({
              icon: 'success',
              text: `Payment was successful and has been confirmed. Payment Reference: ${data.data}`,
              showConfirmButton: true,
              showCloseButton: false,
            }).then((result) => {
              if (result.isConfirmed) window.location.replace('/')
            })
          } else {
            Swal.fire({
              icon: 'error',
              text: data.message,
              showConfirmButton: true,
              showCloseButton: false,
            }).then((result) => {
              if (result.isConfirmed) window.location.replace('/')
            })
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            text: err.message,
            showConfirmButton: true,
            showCloseButton: false,
          }).then((result) => {
            if (result.isConfirmed) window.location.replace('/')
          })
        })
    }
  }, [])

  return (
    <>
      <section style={{ textAlign: 'center' }}>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                position: 'absolute',
                top: '35vh',
              }}
            >
              <SpinnerCircularSplit
                size={60}
                color='#fbbd08'
                secondaryColor='rgba(27, 38, 84, 0.90)'
              />
              <h4 style={{ color: 'rgba(27, 38, 84, 0.90)' }}>Finalizing Transaction...</h4>
              <div>
                <img src={logo} alt='/' width={120} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TransConfirmation
