import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import ThemeProvider from 'react-bootstrap/ThemeProvider'

// import store, { Persistor } from './store';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import {store, persistor} from './redux/store'



ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
        <App />
      </ThemeProvider>
    </PersistGate>
  </Provider>,

  document.getElementById('root')
)
