import { createSlice } from '@reduxjs/toolkit'
import { getPaymentTypes } from './services'

const initialState = {
  paymentTypes: [],
  isLoading: false,
}

const paymentTypeSlice = createSlice({
  name: 'payment-types',
  initialState,
  extraReducers: {
    [getPaymentTypes.pending]: (state) => {
      state.isLoading = true
    },
    [getPaymentTypes.fulfilled]: (state, {payload}) => {
      state.isLoading = false
      state.paymentTypes = payload.data
    },
    [getPaymentTypes.rejected]: (state, {payload}) => {
        state.isLoading = false
        console.log(payload)
    },
  },
})

export default paymentTypeSlice.reducer
