import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { SpinnerCircularSplit } from 'spinners-react'
import logo from '../dist/safepay-logo.png'
import { initialize_transaction } from '../modules/transaction.module'

const WebTransaction = () => {
  const { payment_ref } = useParams()

  useEffect(() => {
    initialize_transaction(payment_ref)
      .then((data) => {
        // setMessage(data?.message)
        data?.status === false &&
          Swal.fire({
            icon: 'error',
            text: data?.message,
            showConfirmButton: true,
            showCloseButton: false,
          }).then((result) => {
            if (result.isConfirmed) window.location.replace('/')
          })
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          text: err.message,
          showConfirmButton: true,
          showCloseButton: false,
        }).then((result) => {
          if (result.isConfirmed) window.location.replace('/')
        })
      })
  }, [])

  return (
    <>
      <section style={{ textAlign: 'center' }}>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                position: 'absolute',
                top: '35vh',
              }}
            >
              <SpinnerCircularSplit
                size={60}
                color='#fbbd08'
                secondaryColor='rgba(27, 38, 84, 0.90)'
              />
              <h4 style={{ color: 'rgba(27, 38, 84, 0.90)' }}>Processing Transaction...</h4>
              <div>
                <img src={logo} alt='/' width={120} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WebTransaction
