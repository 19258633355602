import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Avatar from 'react-avatar'
import { useSelector, useDispatch } from 'react-redux'
import jsonwebtoken from 'jsonwebtoken'

import {
  Overview,
  Transactions,
  Payouts,
  PersonalInfo,
  BusinessInfo,
  PaymentSetup,
  FundsTransfer,
} from '../components/dashboard'
import {
  getAccountBalance,
  getEarningsBalance,
  getWithdrawalBalance,
} from '../modules/services.module'
import { TrendDown, TrendUp, Vault, Wallet } from 'phosphor-react'

const pointer = { cursor: 'default' }

export default function Dashboard() {
  const [credits, setCredits] = useState('')
  const [debits, setDebits] = useState('')
  const [balance, setBalance] = useState({})

  const dispatch = useDispatch()

  const { user_id, phone_number } = jsonwebtoken.decode(sessionStorage.getItem('x-bearer'))
  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    // =================================> total credits
    getEarningsBalance(user_id).then(({ data }) => {
      setCredits(data)
    })

    // ===============================> total debits
    getWithdrawalBalance(user_id).then(({ data }) => {
      setDebits(data)
    })

    // ===============================> account balance
    getAccountBalance(user_id).then(({ data }) => {
      setBalance(data)
    })
  }, [])

  const [overview, setOverview] = useState(false)
  const [tansactions, setTransactions] = useState(true)
  const [payouts, setPayouts] = useState(false)
  const [personalInfo, setPersonalInfo] = useState(false)
  const [businessInfo, setBusinessInfo] = useState(false)
  const [paymentSetup, setPaymentSetup] = useState(false)
  const [fundsTrans, setFundsTrans] = useState(false)

  const resetViews = () => {
    setOverview(false)
    setTransactions(false)
    setPayouts(false)
    setPersonalInfo(false)
    setBusinessInfo(false)
    setPaymentSetup(false)
    setFundsTrans(false)
  }

  return (
    <>
      <Header />
      <div className='page-area'>
        <div className='breadcumb-overlay'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <div className='breadcrumb text-center'>
                <div className='section-headline white-headline text-center'>
                  <h3>Dashboard</h3>
                </div>
                <ul>
                  <li className='home-bread'>Home</li>
                  <li>Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='dsahboard-area bg-color area-padding'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-sm-12 col-xs-12 '>
              <div className='dashboard-head'>
                <div className='row d-flex justify-content-evenly'>
                  <div className='col-md-4 col-sm-3 col-xs-12 '>
                    <div className='single-dash-head'>
                      <div className='dashboard-profile'>
                        <div className='profile-content'>
                          <span>
                            <Avatar
                              name={user && user.account_name !== '' ? user.account_name : 'N/A'}
                              size='80'
                              color='#FFB24D'
                              round={true}
                            />
                          </span>
                          <div className='pro-name'>
                            {user && user.account_name !== '' ? user.account_name : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2 col-sm-3 col-xs-12'>
                    <div className='single-dash-head'>
                      <div className='dashboard-amount'>
                        <div className='amount-content'>
                          <div className='mb-1'>
                            <TrendUp size={42} color='#ffbd66' />
                          </div>
                          <span className='pro-name'>
                            Total Credits
                            <br /> {credits ? `NGN${credits}` : 'N/A'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2 col-sm-3 col-xs-12'>
                    <div className='single-dash-head'>
                      <div className='dashboard-amount'>
                        <div className='amount-content'>
                          <div className='mb-1'>
                            <TrendDown size={42} color='#ffbd66' />
                          </div>
                          <span className='pro-name'>
                            Total Debits
                            <br /> {debits ? `NGN${debits}` : 'N/A'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2 col-sm-3 col-xs-12'>
                    <div className='single-dash-head'>
                      <div className='dashboard-amount'>
                        <div className='amount-content'>
                          <div className='mb-1'>
                            <Wallet size={42} color='#ffbd66' />
                          </div>
                          <span className='pro-name'>
                            Available Bal. <br />
                            {balance ? `NGN${balance.available_balance}` : 'N/A'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2 col-sm-3 col-xs-12'>
                    <div className='single-dash-head'>
                      <div className='dashboard-amount'>
                        <div className='amount-content'>
                          <div className='mb-1'>
                            <Vault size={42} color='#ffbd66' />
                          </div>
                          <span className='pro-name'>
                            Book Bal. <br />
                            {balance ? `NGN${balance.book_balance}` : 'N/A'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-3 col-sm-3 col-xs-12 sticky'>
              <aside className='sidebar'>
                <div className='dashboard-side'>
                  <ul>
                    {/* <li
                      style={pointer}
                      className={overview ? 'active' : ''}
                      onClick={() => {
                        resetViews();
                        setOverview(true);
                      }}
                    >
                      <a>
                        <i className='ti-dashboard'></i>Overview
                      </a>
                    </li> */}
                    <li
                      style={pointer}
                      className={tansactions ? 'active' : ''}
                      onClick={() => {
                        resetViews()
                        setTransactions(true)
                      }}
                    >
                      <a>
                        <i className='ti-pulse'></i>Transactions
                      </a>
                    </li>
                    {/* <li
                      style={pointer}
                      className={payouts ? 'active' : ''}
                      onClick={() => {
                        resetViews()
                        setPayouts(true)
                      }}
                    >
                      <a>
                        <i className='ti-receipt'></i>Payouts
                      </a>
                    </li> */}
                    <li
                      style={pointer}
                      className={personalInfo ? 'active' : ''}
                      onClick={() => {
                        resetViews()
                        setPersonalInfo(true)
                      }}
                    >
                      <a>
                        <i className='ti-user'></i>Personal Info
                      </a>
                    </li>
                    <li
                      style={pointer}
                      className={businessInfo ? 'active' : ''}
                      onClick={() => {
                        resetViews()
                        setBusinessInfo(true)
                      }}
                    >
                      <a>
                        <i className='ti-notepad'></i>Business Info
                      </a>
                    </li>
                    <li
                      style={pointer}
                      className={paymentSetup ? 'active' : ''}
                      onClick={() => {
                        resetViews()
                        setPaymentSetup(true)
                      }}
                    >
                      <a>
                        <i className='ti-panel'></i>Payment Setup
                      </a>
                    </li>
                    <li
                      style={pointer}
                      className={fundsTrans ? 'active' : ''}
                      onClick={() => {
                        resetViews()
                        setFundsTrans(true)
                      }}
                    >
                      <a>
                        <i className='ti-exchange-vertical'></i>Funds Transfer
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='dashboard-support'>
                  <div className='support-inner'>
                    <div className='help-support'>
                      <i className='flaticon-107-speech-bubbles'></i>
                      <a href='contact.html'>
                        <span className='help-text'>Need Help?</span>
                      </a>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
            <div className='col-md-9 col-sm-9 col-xs-12'>
              <div className='dashboard-content'>
                {/* <h3 style={{ textAlign: 'center' }}>
                  Graphs to be displayed here
                </h3> */}
                {overview && <Overview />}
                {tansactions && <Transactions />}
                {payouts && <Payouts />}
                {personalInfo && <PersonalInfo info={user} />}
                {businessInfo && <BusinessInfo info={user} />}
                {paymentSetup && <PaymentSetup info={user} />}
                {fundsTrans && <FundsTransfer />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
