import React, { useEffect, useState } from 'react';
import './style.css';
import { Container, Form, Button, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const Payouts = () => {
   const { user: { merchant_id } } = useSelector((state) => state.auth)

  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    try {
      fetch(`${process.env.REACT_APP_GET_OUTFLOW}${merchant_id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            setTableData(data.data);
          }
        });
    } catch (err) {
      console.log('Error fetching data!');
    }
  }, []);

  const cleanDate = (d) => {
    let monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let date = new Date(d.replace('at', ''));

    return `${
      monthNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  };

  return (
    <div>
      <Container fluid>
        <div className='payout-topBar' data-aos='fade-up'>
          <Form.Group className='mb-3' size='sm'>
            {/* <Form.Label>Start Date</Form.Label> */}
            <Form.Control type='date' placeholder='End Date' />
          </Form.Group>
          <Form.Group className='mb-3' size='sm'>
            {/* <Form.Label>End Date</Form.Label> */}
            <Form.Control type='date' placeholder='Start Date' />
          </Form.Group>

          <Form.Select style={{ height: 35, width: 'max(20vw)', color: 'black' }}>
            <option>Select Status</option>
            <option value='1'>One</option>
            <option value='2'>Two</option>
            <option value='3'>Three</option>
          </Form.Select>
          <Button variant='primary' disabled={true}>
            Download Report
          </Button>
        </div>
        <div className='comp-body'>
          <Table bordered>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Transaction Reference</th>
                <th>Date</th>
                <th>Channel</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((item, key) => (
                  <tr key={key}>
                    <td>NGN{item.Amount}</td>
                    <td>{item.TransactionRef}</td>
                    <td>{cleanDate(item.log_date)}</td>
                    <td>{item.Channel}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Container>
    </div>
  )
};
