import { createSlice } from '@reduxjs/toolkit'
import {
  getMerchantTransactions,
  getAllTransactions,
  filterTransactionsByDate,
  filterTransactionsByChannel,
  filterTransactionsByAmount,
  tranferFunds
} from './services'
import Swal from 'sweetalert2'

const initialState = {
  transactionHistory: {},
  allTransactions: [],
  sortedByDate: [],
  sortedByChannel: [],
  sortedByAmount: [],
  fundsTransfer: [],

  allTransactionsNumberOfPages: 0,
  dateSortNumOfPages: 0,
  channelSortNumOfPages: 0,
  amountSortNumOfPages: 0,

  transactionsIsLoading: false,
  allTransactionsLoading: false,
  dateSortLoading: false,
  channelSortLoading: false,
  amountSortLoading: false,
  fundsTransferLoading: false
}

const transactionSlice = createSlice({
  name: 'transaction-history',
  initialState,
  extraReducers: {
    [getMerchantTransactions.pending]: (state) => {
      state.transactionsIsLoading = true
    },
    [getMerchantTransactions.fulfilled]: (state, { payload }) => {
      state.transactionsIsLoading = false
      // state.transactionHistory = payload
      if (payload.status) {
        state.transactionHistory = payload
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: `${payload.message}`,
          showConfirmButton: false,
          showCloseButton: true,
        })
        state.transactionHistory = []
      }
    },
    [getMerchantTransactions.rejected]: (state, { payload }) => {
      state.transactionsIsLoading = false
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: `${payload.message}`,
        showConfirmButton: false,
        showCloseButton: true,
      })
    },

    // ----------------------------------------------------------------
    [getAllTransactions.pending]: (state) => {
      state.allTransactionsLoading = true
    },
    [getAllTransactions.fulfilled]: (state, { payload }) => {
      state.allTransactionsLoading = false
      state.allTransactions = payload.data
      state.allTransactionsNumberOfPages = payload.max_page_number
    },
    [getAllTransactions.rejected]: (state, { payload }) => {
      state.allTransactionsLoading = false
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: `${payload.message}`,
        showConfirmButton: false,
        showCloseButton: true,
      })
    },

    // ----------------------------------------------------------------
    [filterTransactionsByDate.pending]: (state) => {
      state.dateSortLoading = true
    },
    [filterTransactionsByDate.fulfilled]: (state, { payload }) => {
      state.dateSortLoading = false
      state.sortedByDate = payload.data
      state.dateSortNumOfPages = payload.max_page_number
    },
    [filterTransactionsByDate.rejected]: (state, { payload }) => {
      state.dateSortLoading = false
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: `${payload.message}`,
        showConfirmButton: false,
        showCloseButton: true,
      })
    },

    // ----------------------------------------------------------------
    [filterTransactionsByChannel.pending]: (state) => {
      state.channelSortLoading = true
    },
    [filterTransactionsByChannel.fulfilled]: (state, { payload }) => {
      state.channelSortLoading = false
      state.sortedByChannel = payload.data
      state.channelSortNumOfPages = payload.max_page_number
    },
    [filterTransactionsByChannel.rejected]: (state, { payload }) => {
      state.channelSortLoading = false
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: `${payload.message}`,
        showConfirmButton: false,
        showCloseButton: true,
      })
    },

    // ----------------------------------------------------------------
    [filterTransactionsByAmount.pending]: (state) => {
      state.amountSortLoading = true
    },
    [filterTransactionsByAmount.fulfilled]: (state, { payload }) => {
      state.amountSortLoading = false
      state.sortedByAmount = payload.data
      state.amountSortNumOfPages = payload.max_page_number
    },
    [filterTransactionsByAmount.rejected]: (state, { payload }) => {
      state.amountSortLoading = false
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: `${payload.message}`,
        showConfirmButton: false,
        showCloseButton: true,
      })
    },

    // ----------------------------------------------------------------
    [tranferFunds.pending]: (state) => {
      state.fundsTransferLoading = true
    },
    [tranferFunds.fulfilled]: (state, { payload }) => {
      state.fundsTransferLoading = false
      if (payload.status) {
        Swal.fire({
          icon: 'success',
          title: 'Successful',
          text: `${payload.message}`,
          showConfirmButton: false,
          showCloseButton: true,
        })
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: `${payload.message}`,
          showConfirmButton: false,
          showCloseButton: true,
        })
      }
    },
    [tranferFunds.rejected]: (state, { payload }) => {
      state.fundsTransferLoading = false
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: `${payload.message}`,
        showConfirmButton: false,
        showCloseButton: true,
      })
    },
  },
})

export default transactionSlice.reducer
