const bankRefs = [
  { name: 'ACCESS BANK', id: '044' },
  { name: 'CITIBANK', id: '023' },
  { name: 'DIAMOND BANK', id: '063' },
  { name: 'ECOBANK NIGERIA', id: '050' },
  { name: 'ENTERPRISE BANK', id: '084' },
  { name: 'FIDELITY BANK', id: '070' },
  { name: 'FIRST BANK OF NIGERIA', id: '011' },
  { name: 'FIRST CITY MONUMENT BANK', id: '214' },
  { name: 'GUARANTY TRUST BANK', id: '058' },
  { name: 'HERITAGE BANK', id: '030' },
  { name: 'JAIZ BANK', id: '301' },
  { name: 'KEYSTONE BANK', id: '082' },
  { name: 'MAINSTREET BANK', id: '014' },
  { name: 'SKYE BANK', id: '076' },
  { name: 'STANBIC IBTC BANK', id: '221' },
  { name: 'STANDARD CHARTERED BANK', id: '068' },
  { name: 'STERLING BANK', id: '232' },
  { name: 'UNION BANK OF NIGERIA', id: '032' },
  { name: 'UNITED BANK FOR AFRICA', id: '033' },
  { name: 'UNITY BANK', id: '215' },
  { name: 'WEMA BANK', id: '035' },
  { name: 'ZENITH BANK', id: '057' },
]

export default bankRefs
