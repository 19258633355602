import React from 'react';
import routes from '../routes';

export default function Login() {
  return (
    <div>
      <a className='s-menu' href={routes.login}>
        Login
      </a>
    </div>
  );
}
