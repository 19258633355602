import { createSlice } from '@reduxjs/toolkit'
import { getMerchantsDetails, getMerchantBalance } from './services'
import Swal from 'sweetalert2'


const initialState = {
  allMerchants: [],
  merchantAccountBalance: {},

  loadingMerchants: false,
  loadingAccountBalance: false
}

const merchantSlice = createSlice({
  name: 'merchants-info',
  initialState,
  extraReducers: {
    [getMerchantsDetails.pending]: (state) => {
      state.loadingMerchants = true
    },
    [getMerchantsDetails.fulfilled]: (state, { payload }) => {
      state.loadingMerchants = false
      state.allMerchants = payload.data
    },
    [getMerchantsDetails.rejected]: (state, { error }) => {
      state.loadingMerchants = false
      // console.log(error)
       Swal.fire({
         icon: 'error',
         title: 'Oops...',
         text: `${error}`,
         showConfirmButton: false,
         showCloseButton: true,
       })
    },

    // ---------------------------------------------------
    [getMerchantBalance.pending]: (state) => {
      state.loadingAccountBalance = true
    },
    [getMerchantBalance.fulfilled]: (state, { payload }) => {
      state.loadingAccountBalance = false
      state.merchantAccountBalance = payload.data
    },
    [getMerchantBalance.rejected]: (state, { error }) => {
      state.loadingAccountBalance = false
      // console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${error}`,
        showConfirmButton: false,
        showCloseButton: true,
      })
    },
  },
})

export default merchantSlice.reducer