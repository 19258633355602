import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';

// import db from '../firebase';

export default function Contact() {

  return (
    <React.Fragment>
      <Header />
      <div id='contact-page-area'>
        <div className='breadcumb-overlay'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <div className='breadcrumb text-center'>
                <div className='section-headline white-headline text-center'>
                  <h3>Contact</h3>
                </div>
                <ul>
                  <li className='home-bread'>Home</li>
                  <li>Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='contact-area bg-color area-padding'>
        <div className='container'>
          <div className='row'>
            <div className='contact-inner'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='contact-icon text-center'>
                  <div className='single-icon'>
                    <i className='fa fa-mobile'></i>
                    <p>
                      Call our 24/7 call center on
                      <br />
                      <span>+234 700 723 3729</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='contact-icon text-center'>
                  <div className='single-icon'>
                    <i className='fa fa-envelope-o'></i>
                    <p>
                      Email: safepay@instantdeposit.africa
                      <br />
                      <span>Web: www.instantdeposit.africa</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='contact-icon text-center'>
                  <div className='single-icon'>
                    <i className='fa fa-map-marker'></i>
                    <p>
                      Location : Genesys Tech Hub, KM7, <br />
                      <span>Enugu-Port Harcourt Expressway, Enugu</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <div className='contact-images'>
                <img src='img/background/sitting.png' alt='' />
              </div>
            </div>
            {/* contact form */}
            <ContactForm />
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
