const formatDate = (d) => {
  let monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  let date = new Date(d.replace('at', ''))

  return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

export default formatDate
