import React from 'react';
// import { signOut } from '@firebase/auth';
import { onAuthStateChanged } from '@firebase/auth';
import { useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Payment from '../components/Payment';
import { auth } from '../firebase';
// import { Link } from 'react-router-dom';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import SignupButton from '../components/SignupButton';

// redux
import { useDispatch } from 'react-redux';

export default function Homepage() {
  // const user = useSelector((state) => state.isLoggedIn);
  const dispatch = useDispatch();

  // console.log(user);

  const [user, setUser] = useState(null);
  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  const logout = (e) => {
    console.log(auth);
    console.log(user);
    console.log('Logging out');
    // signOut(auth);
    console.log('Logged Out');
  };

  // Testing the final signIn here
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation');
    }
    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
  }

  return (
    <React.Fragment>
      <Header />
      <div className='slide-area fix'>
        <div className='display-table'>
          <div className='display-table-cell'>
            <div className='container'>
              <div className='row'>
                <div className='slide-text-inner'>
                  <div className='col-md-6 col-sm-12 col-xs-12'>
                    <div className='slide-content'>
                      <h2 className='title2'>
                        Make payments in two easy secure steps
                      </h2>
                      <p>
                        The safe payment alternative to power your payments
                        without need for long queues in banking halls or
                        exposing your card details.
                      </p>
                      <div className='layer-1-3'>
                        {user ? null : <SignupButton />}
                      </div>
                    </div>
                  </div>

                  <div
                    id='payment-component'
                    className='col-md-6 col-sm-12 col-xs-12'
                  >
                    <div>
                      <Payment />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='about-area bg-color2 area-padding'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <div className='about-image'>
                <img src='img/about/vd.jpg' alt='' className='ab-first-img' />
                <img src='img/about/vd1.jpg' alt='' className='ab-second-img' />
              </div>
              <div className='about-image'>
                <img src='img/about/vd2.jpg' alt='' className='ab-first-img' />
              </div>
            </div>
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <div className='about-content'>
                <h3>
                  Why choose our secure, fast and seamless payment platform?
                </h3>
                <p className='hidden-sm'>
                  Would you rather not make and receive payments anywhere and at
                  any time? Why spend time in long queues in banking halls? Why
                  expose your debit card details? We provide you with secure,
                  quick, efficient and seamless financial transactions
                </p>
                <div className='about-details'>
                  <div className='single-about'>
                    <a href='/#'>
                      <i className='flaticon-079-graphic'></i>
                    </a>
                    <div className='icon-text'>
                      <h5>Accurate Payments</h5>
                      <p>
                        There is no overpayment or underpayment when you use our
                        seamless platform; you pay exactly what you need to
                      </p>
                    </div>
                  </div>
                  <div className='single-about'>
                    <a href='/#'>
                      <i className='flaticon-007-document-2'></i>
                    </a>
                    <div className='icon-text'>
                      <h5>Safe & Secure</h5>
                      <p>
                        Your payments on our platform will not expose your
                        sensitive financial information online. Generate your
                        payment reference and use your bank's USSD to pay
                      </p>
                    </div>
                  </div>
                  <div className='single-about'>
                    <a href='/#'>
                      <i className='flaticon-107-speech-bubbles'></i>
                    </a>
                    <div className='icon-text'>
                      <h5>Available to anyone</h5>
                      <p>
                        Now, your friends and relatives can now help you pay
                        those fees from anywhere. Just send them your payment
                        reference
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='overview-area bg-color fix area-padding'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <div className='overview-content'>
                <div className='overview-images'>
                  <img src='img/about/ladypay2crop.jpg' alt='' />
                </div>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <div className='overview-text'>
                <h3>
                  SafePAY can benefit your organization in several ways you may
                  not have imagined
                </h3>
                <p>
                  We understand the unique challenges facing organizations
                  regarding receiving payments. Payments need not be a hassle
                  for you and your stakeholders.
                </p>
                <ul>
                  <li>
                    <a href='/#'>
                      We save you the stress of manually tracking payments from
                      bank statements and WhatsApp messages
                    </a>
                  </li>
                  <li>
                    <a href='/#'>
                      For every payment made to you, we send you instant
                      notification with all relevant details
                    </a>
                  </li>
                  <li>
                    <a href='/#'>
                      We give you executive dashboard to monitor all your
                      payments
                    </a>
                  </li>
                </ul>
                {/* <Link to="/signup" className="overview-btn">Sign Up Now</Link> */}
                {user ? null : <SignupButton />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
