// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from '@firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAa_1hMa8TY7JhGQQijqxBIuJEosX_IFZA',
  authDomain: 'safepay-login.firebaseapp.com',
  projectId: 'safepay-login',
  storageBucket: 'safepay-login.appspot.com',
  messagingSenderId: '455652323142',
  appId: '1:455652323142:web:eee46991f8534a4f7e7f47',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export default getFirestore();
