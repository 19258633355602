import axios from 'axios'
import Swal from 'sweetalert2'

axios.defaults.headers.common['x-access-token'] = process.env.REACT_APP_API_TOKEN

// ============================>  merchant detials
export const getMerchantDetails = async (phone_number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_MERCHANT_DETAILS}${phone_number}`, {
      mode: 'cors',
      credentials: 'same-origin',
    })
    return response.data
  } catch (err) {
    // console.log(err.response.data.message)
    console.log(err.message)
  }
}

// =======================> account balance
export const getAccountBalance = async (user_id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GET_ACCOUNT_BALANCE}${user_id}`, {
      mode: 'cors',
      credentials: 'same-origin',
    })
    return response.data
  } catch (err) {
    // console.log(err.response.data.message)
    console.log(err.message)
  }
}

// ============================>  earnings
export const getEarningsBalance = async (user_id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_TOTAL_CREDITS}${user_id}`, {
      mode: 'cors',
      credentials: 'same-origin',
    })
    return response.data
  } catch (err) {
    // console.log(err.response.data.message)
    console.log(err.message)
  }
}

// ============================>  withdrawals
export const getWithdrawalBalance = async (user_id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_TOTAL_WITHDRAWALS}${user_id}`, {
      mode: 'cors',
      credentials: 'same-origin',
    })
    return response.data
  } catch (err) {
    // console.log(err.response.data.message)
    console.log(err.message)
  }
}

// ============================>  transactions
export const getTransactions = async ({ merchant_id, pagination }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_TRANSACTIONS}`,
      { merchant_id: merchant_id, page_number: pagination },
      {
        mode: 'cors',
        credentials: 'same-origin',
      }
    )
    return response.data
  } catch (err) {
    console.log(err.message)
  }
}

// ==============================>  filter transactions by channels
export const filterByChannels = async ({ merchant_id, pagination, channel }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_FILTER_TRANSACTIONS_BY_CHANNEL}`,
      { merchant_id, page_number: pagination, channel },
      {
        mode: 'cors',
        credentials: 'same-origin',
      }
    )
    return response.data
  } catch (err) {
    console.error(err.message)
  }
}

// ====================================> verify bank account number
export const verifyBankAccount = async ({ account_no, bank_code }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_VERIFY_BANK_ACCOUNT}/${account_no}/${bank_code}`,
      {
        mode: 'cors',
        credentials: 'same-origin',
      }
    )
    return response.data
  } catch (err) {
    console.log(err)
    if (err.response.data)
      Swal.fire({
        icon: 'error',
        title: `${err.response.data.message}`,
        showConfirmButton: false,
        showCloseButton: true,
      })
    else
      Swal.fire({
        icon: 'error',
        title: `${err.message}`,
        showConfirmButton: false,
        showCloseButton: true,
      })
  }
}
