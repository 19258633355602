import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './style.css';
import { Container, Form, Button } from 'react-bootstrap';
import bankRefs from '../../../modules/bankReferences.module';

export const PaymentSetup = ({ info }) => {
   const { user: { merchant_id } } = useSelector((state) => state.auth)  

  const [uploadItems, setUplaodItems] = useState('no');
  const [newDetails, setNewDetails] = useState({
    Account_number: info.bank_account,
    Bank_id: info.bank_id,
  });
  const [message, setMessage] = useState("")


  const handleItemsUpload = ({ target }) => {
    setUplaodItems(target.value);
  };

  const handleDetails = ({ target }) => {
    setNewDetails((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  };
  
  const handleSubmit = (evt) => {
    evt.preventDefault();

    const _data = { merchant_id, Account_number: newDetails.Account_number, Bank_id: newDetails.Bank_id }
    try {
      fetch(`${process.env.REACT_APP_UPDATE_BANK_ACCOUNT}`, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json, charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Methods': 'POST',
          'x-access-token': `${process.env.REACT_APP_API_TOKEN}`,
        },
        body: JSON.stringify(_data),
      })
        .then((res) => res.json())
        .then((data) => {
          setMessage(data.message);
        });
    } catch (err) {
      console.log('Check your network connection');
    }
  };
  return (
    <div>
      <Container fluid>
        <div className='payment-setup' data-aos='fade-up'>
          <form className='left' onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Select
                style={{ height: 35, width: 'max(100%)', color: 'black' }}
                onChange={handleDetails}
                value={newDetails.Bank_id}
                name='Bank_id'
              >
                <option>Select your Bank</option>
                {bankRefs.map((bank, key) =>
                  info.bank_id == bank.id ? (
                    <option key={key} value={bank.id} selected>
                      {bank.name}
                    </option>
                  ) : (
                    <option key={key} value={bank.id}>
                      {bank.name}
                    </option>
                  )
                )}
              </Form.Select>
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Account Number'
                value={newDetails.Account_number || ''}
                name='Account_number'
                onChange={handleDetails}
                disabled={false}
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Account Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Account Name'
                value={info.account_name || ''}
                disabled={true}
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>BVN</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter BVN (If business is not registered)'
                disabled={true}
              />
            </Form.Group>
            <p>{message && message}</p>
            <div className='account-submit'>
              <Button variant='primary' type='submit'>
                Update
              </Button>
            </div>
          </form>

          <form className='right' onSubmit={handleSubmit}>
            <Form.Group>
              <div className='question-radios'>
                <p>Do you want to upload payment items?</p>
                <Form.Check
                  inline
                  label='Yes'
                  name='uploadPaymentItems'
                  type='radio'
                  value='yes'
                  onChange={handleItemsUpload}
                  checked={uploadItems === 'yes' && true}
                />
                <Form.Check
                  inline
                  label='No'
                  name='uploadPaymentItems'
                  type='radio'
                  value='no'
                  onChange={handleItemsUpload}
                  checked={uploadItems === 'no' && true}
                />
              </div>
            </Form.Group>
            {uploadItems === 'yes' && (
              <>
                <Form.Group controlId='formFile'>
                  <Form.Label>Upload payment items (Excel/CSV only)</Form.Label>
                  <Form.Control
                    type='file'
                    accept='text/csv, application/vnd.ms-excel'
                    disabled={true}
                  />
                </Form.Group>
              </>
            )}

            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Payment category</Form.Label>
              <Form.Control type='text' placeholder='Enter payment item category' disabled={true} />
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Payment Item</Form.Label>
              <Form.Control type='text' placeholder='Enter name of payment item' disabled={true} />
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Description</Form.Label>
              <Form.Control type='text' placeholder='Describe payment item' disabled={true} />
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter amount for payment item'
                disabled={true}
              />
            </Form.Group>
            <div className='paymentItems-submit'>
              <Button variant='primary' type='submit' disabled={true}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  )
};
