import { createSlice } from '@reduxjs/toolkit'
import { login } from './services'
import Swal from 'sweetalert2'

const initialState = {
  user: {},
  isLoadingUser: false,
  dashboardSignedIn: false,
  err_login: '',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.dashboardSignedIn = false
    },

    signIn: (state, { payload }) => {
      // if (payload === process.env.REACT_APP_ADMIN_SECRET) {
      //   state.dashboardSignedIn = true
      // } else {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: 'Wrong Passord',
      //     showConfirmButton: false,
      //     showCloseButton: true,
      //   })
      // }
      state.user = payload
      // console.log(payload)
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.isLoadingUser = true
    },
    [login.fulfilled]: (state, { payload }) => {
      state.isLoadingUser = false
      state.dashboardSignedIn = payload
    },
    [login.rejected]: (state, { payload }) => {
      console.log(payload)
      state.isLoadingUser = false
    },
  },
})

export const { logout, signIn } = authSlice.actions

export default authSlice.reducer
