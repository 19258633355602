import React from 'react'
import { useState } from 'react';
import db from '../firebase';
import { collection, addDoc } from '@firebase/firestore';


export default function ContactForm() {
    const [contact, setContact] = useState({});
    const [submitMessage, setSubmitMessage] = useState("")

    const handleContact = async (e) => {
        e.preventDefault();


        // firestore connection
        const collectionRef = collection(db, "contacts")
        const payload = {
          name: contact.name,
          email: contact.email,
          subject: contact.msg_subject,
          message: contact.message,
        }
        await addDoc(collectionRef, payload)
        .then(()=>{
          setSubmitMessage(`Contact Posted Succesfully`)
          setContact({
              name: "",
              email: "",
              msg_subject: "",
              message: ""
          })
          setInterval(() => {
              setSubmitMessage("");
          }, 5000);
        })

      
    }

    const contactUpdate = (e) => {
        e.preventDefault()
        setContact((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
          }));
    }
    return (
        <React.Fragment>
        <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='contact-form'>
                <div className='row'>
                    <form
                    id='contactForm'
                    className='contact-form'
                    >
                    <div className='col-md-6 col-sm-6 col-xs-12'>
                        <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        data-error='Please enter your name'
                        onChange={contactUpdate}
                        value={contact.name}
                        />
                        <div className='help-block with-errors'></div>
                    </div>
                    <div className='col-md-6 col-sm-6 col-xs-12'>
                        <input
                        type='email'
                        className='email form-control'
                        id='email'
                        name = 'email'
                        placeholder='Email'
                        required
                        data-error='Please enter your email'
                        onChange={contactUpdate}
                        value={contact.email}
                        />
                        <div className='help-block with-errors'></div>
                    </div>
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                        <input
                        type='text'
                        id='msg_subject'
                        name='msg_subject'
                        className='form-control'
                        placeholder='Subject'
                        required
                        data-error='Please enter your message subject'
                        onChange={contactUpdate}
                        value={contact.msg_subject}
                        />
                        <div className='help-block with-errors'></div>
                    </div>
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                        <textarea
                        id='message'
                        name='message'
                        rows='7'
                        placeholder='Message'
                        className='form-control'
                        required
                        data-error='Write your message'
                        onChange={contactUpdate}
                        value={contact.message}
                        ></textarea>
                        <div className='help-block with-errors'></div>
                    </div>
                    <div className='col-md-12 col-sm-12 col-xs-12 text-center'>
                        <p style={{color: "green"}}>{submitMessage}</p>
                        <button
                        type='submit'
                        id='submit'
                        className='add-btn contact-btn'
                        onClick={handleContact}
                        >
                        Send Message
                        </button>
                        <div
                        id='msgSubmit'
                        className='h3 text-center hidden'
                        ></div>
                        <div className='clearfix'></div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
    </React.Fragment>
    )
}
