import jsonwebtoken from 'jsonwebtoken';
import React, { useEffect, useState } from 'react';
import routes from '../routes';
import Login from './Login';
import Logout from './Logout';

export default function Header() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (sessionStorage.getItem('x-bearer')) {
      setUser(jsonwebtoken.decode(sessionStorage.getItem('x-bearer')));
    }
  }, []);

  return (
    <>
      <header className='header-one'>
        {/* <div className='topbar-area'>
          <div className='container'>
            <div className='row'>
              <div className=' col-md-6 col-sm-6 col-xs-12'>
                <div className='topbar-left'>
                  <ul>
                    <li>
                      <a
                        href='mailto:safepay.idl@gmail.com'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='fa fa-envelope'></i>safepay.idl@gmail.com
                      </a>
                    </li>
                    <li>
                      <a href={routes.contact}>+234 700 723 3729</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='topbar-right'>
                  <ul>
                    <li>
                      <a href='/#'>
                        <img src='img/icon/w1.png' alt='' />
                        ENG
                      </a>
                      <ul>
                        <li>
                          <a href='/#'>
                            <img src='img/icon/w2.png' alt='' />
                            DEU
                          </a>
                        </li>
                        <li>
                          <a href='/#'>
                            <img src='img/icon/w3.png' alt='' />
                            ESP
                          </a>
                        </li>
                        <li>
                          <a href='/#'>
                            <img src='img/icon/w4.png' alt='' />
                            FRA
                          </a>
                        </li>
                        <li>
                          <a href='/#'>
                            <img src='img/icon/w5.png' alt='' />
                            KSA
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div id='sticker' className='header-area hidden-xs'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-sm-12'>
                <div className='row'>
                  <div className='col-md-3 col-sm-3'>
                    <div className='logo'>
                      <a className='navbar-brand page-scroll' href='/'>
                        <img
                          className='logoimg'
                          src='./safepay-logo1.png'
                          alt=''
                        />
                      </a>
                    </div>
                  </div>
                  <div className='col-md-9 col-sm-9'>
                    <div className='header-right-link'>
                      {user ? <Logout /> : <Login />}
                    </div>
                    <nav className='navbar navbar-default'>
                      <div
                        className='collapse navbar-collapse'
                        id='navbar-example'
                      >
                        <div className='main-menu'>
                          <ul className='nav navbar-nav navbar-right'>
                            <li>
                              <a href={routes.home}>Home</a>
                            </li>

                            <li>
                              <a href={routes.contact}>Contact</a>
                            </li>
                            {user && (
                              <li>
                                <a href={routes.dashboard}>
                                  Dashboard
                                </a>
                              </li>
                            )}
                            {/* {user && user.user_id === "001" && (
                              <li>
                                <NavLink to={routes.admin}>
                                  Admin Dashboard
                                </NavLink>
                              </li>
                            )} */}
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mobile-menu-area hidden-lg hidden-md hidden-sm'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='mobile-menu'>
                  <div className='logo'>
                    <a href={routes.home}>
                      <img src='./safepay-logo1.png' alt='' />
                    </a>
                  </div>
                  {/* <nav id='dropdown'>
                    <ul>
                      <li>
                        <a className='pages' href='/'>
                          Home
                        </a>
                        <ul className='sub-menu'>
                          <li>
                            <a href='/'>Home 01</a>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <a href='/contact'>contacts</a>
                      </li>
                    </ul>
                  </nav> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
