import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

axios.defaults.headers.common['x-access-token'] = process.env.REACT_APP_API_TOKEN

// ---------------------------------------------------------------------------------------------------------get payment types
export const getPaymentTypes = createAsyncThunk('payment/get-payment-types', async (thunkAPI) => {
  try {
    const res = await axios.get(process.env.REACT_APP_GET_PAYMENT_TYPES, {
      mode: 'cors',
      credentials: 'same-origin',
    })
    return res.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

// ------------------------------------------------------------------------------------------------------- verify payment and user information
export const verifyPaymentReference = createAsyncThunk(
  'payment/verify-payment-and-user',
  async ({ payment_type, ref }, thunkAPI) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_VERIFY_PAYMENT_AND_USER}${payment_type}/${ref}`,
        {
          mode: 'cors',
          credentials: 'same-origin',
        }
      )
      return res.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

// ------------------------------------------------------------------------------------------------------- auth/login
export const login = createAsyncThunk('auth/login', async (password, thunkAPI) => {
  try {
    // return res.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

// ------------------------------------------------------------------------------------------------------- verify payment and user information
export const getMerchantsDetails = createAsyncThunk(
  'dashboard/get-all-merchant-info',
  async (thunkAPI) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_GET_ALL_MERCHANTS}`, {
        mode: 'cors',
        credentials: 'same-origin',
      })
      return res.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// ------------------------------------------------------------------------------------------------------- fetch merchant transaction history
export const getMerchantTransactions = createAsyncThunk(
  'dashboard/merchant-transaction-history',
  async (merchant_id, thunkAPI) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_MERCHANT_TRANSACTION_HISTORY}${merchant_id}`,
        {
          mode: 'cors',
          credentials: 'same-origin',
        }
      )
      return res.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// ------------------------------------------------------------------------------------------------------- fetch all transactions via-pagination
export const getAllTransactions = createAsyncThunk(
  'dashboard/merchant-transactions-report',
  async ({ pagination, merchant_id }, thunkAPI) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_TRANSACTIONS}`,
        { page_number: pagination, merchant_id: merchant_id },
        {
          mode: 'cors',
          credentials: 'same-origin',
        }
      )

      return res.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// ------------------------------------------------------------------------------------------------------- filter transactions by date
export const filterTransactionsByDate = createAsyncThunk(
  'dashboard/all-transactions-date-filter',
  async (payload, thunkAPI) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_FILTER_TRANSACTIONS_BY_DATE}`,
        { ...payload },
        {
          mode: 'cors',
          credentials: 'same-origin',
        }
      )

      return res.data
    } catch (error) {
      // console.log(error)
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// ------------------------------------------------------------------------------------------------------- filter transactions by channels
export const filterTransactionsByChannel = createAsyncThunk(
  'dashboard/all-transactions-channel-filter',
  async (payload, thunkAPI) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_FILTER_TRANSACTIONS_BY_CHANNEL}`,
        { ...payload },
        {
          mode: 'cors',
          credentials: 'same-origin',
        }
      )

      return res.data
    } catch (error) {
      // console.log(error.response.data)
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// ------------------------------------------------------------------------------------------------------- filter transactions by amount
export const filterTransactionsByAmount = createAsyncThunk(
  'dashboard/all-transactions-amount-filter',
  async (payload, thunkAPI) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_FILTER_TRANSACTIONS_BY_AMOUNT}`,
        { ...payload },
        {
          mode: 'cors',
          credentials: 'same-origin',
        }
      )

      return res.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// ------------------------------------------------------------------------------------------------------- get account balance
export const getMerchantBalance = createAsyncThunk(
  'dashboard/get-account-balance',
  async (merchant_id, thunkAPI) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_GET_ACCOUNT_BALANCE}/${merchant_id}`, {
        mode: 'cors',
        credentials: 'same-origin',
      })

      return res.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// ------------------------------------------------------------------------------------------------------- funds transfer
export const tranferFunds = createAsyncThunk(
  'dashboard/funds-transfer',
  async (payload, thunkAPI) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_FUNDS_TRANSFER}`,
        { ...payload },
        {
          mode: 'cors',
          credentials: 'same-origin',
        }
      )

      return res.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)
