import { useState } from 'react'
import './style.css'
import { useSelector, useDispatch } from 'react-redux'
import { SpinnerCircularSplit } from 'spinners-react'
import { tranferFunds } from '../../../redux/slices/services'
import { Divider } from 'semantic-ui-react'

export const FundsTransfer = () => {
  const {
    user: { merchant_id, bank_name },
  } = useSelector((store) => store.auth)

  const { fundsTransferLoading } = useSelector((store) => store.transactions)
  const dispatch = useDispatch()

  const [amount, setAmount] = useState(0)

  const handleTransfer = (evt) => {
    evt.preventDefault()
    dispatch(tranferFunds({ merchant_id, amount }))
  }

  return (
    <>
      <div className='comp-body' data-aos='fade-up'>
        <div className='text-center'>
          <div className='mb-1'>
            <p>
              Transfer <span className='primary-color'> NGN{amount} </span> to your {bank_name}{' '}
              Account
            </p>
          </div>
          <form method='post' onSubmit={handleTransfer}>
            <div>
              <input
                type='number'
                placeholder='Amount'
                name='amount'
                onChange={({ target }) => {
                  setAmount(target.value)
                }}
                value={amount}
                min='0'
                required
              />
            </div>
            <div className='my'>
              {fundsTransferLoading ? (
                <SpinnerCircularSplit size={30} color='#ffb24d' />
              ) : (
                <button type='submit'>Transfer</button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
