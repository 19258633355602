import React, { useState, useEffect } from 'react'
import { css } from '@emotion/react'
import { Link, useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'

import bcryptjs from 'bcryptjs'
import { verifyPhoneNumber } from 'nigerian-phone-number-validator'
import Icon from 'react-eva-icons'
import 'react-pure-modal/dist/react-pure-modal.min.css'
import Swal from 'sweetalert2'
// import ReactHTMLDatalist from 'react-html-datalist'
import bankRefs from '../modules/bankReferences.module'
import { verifyBankAccount } from '../modules/services.module'

const override = css`
  display: block;
  margin: 0 auto;
`

let labelStyle = {
  fontSize: 12,
  marginBottom: 2,
  lineHeight: 1.1,
}

const EvaIcon = ({ name, size }) => {
  return (
    <Icon
      name={name}
      size={size} // small, medium, large, xlarge
      fill='green'
      animation={{
        type: 'pulse', // zoom, pulse, shake, flip
        hover: false,
        infinite: true,
      }}
    />
  )
}

const form_type_select = {
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  justifyContent: 'center',
  columnGap: 20,
  fontSize: 13,
}

export default function SignupForm() {
  const [registration, setRegistration] = useState({})
  const [info, setInfo] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [err, setErr] = useState('')
  const [loading, setLoading] = useState(false)
  const [formView, setFormView] = useState('other')
  const [haveSite, setHaveSite] = useState(false)
  const [message, setMessage] = useState('')
  const [bankList, setBankList] = useState([])

  const navigate = useNavigate()

  const changeFunc = ({ target }) => {
    if (target.name === 'bankName') {
      let bank = bankList.find((bank) => bank.id === target.value)

      setRegistration((prev) => ({
        ...prev,
        bankName: bank?.name,
      }))

      setRegistration((prev) => ({
        ...prev,
        bankCode: bank?.id,
      }))
    } else if (target.name === 'accountNumber') {
      setRegistration((prev) => ({
        ...prev,
        [target.name]: target.value,
      }))

      if (target.value.length != 10) return

      verifyBankAccount({
        account_no: target.value,
        bank_code: registration.bankCode,
      }).then(({ data }) => {
        console.log(data.account_name)
        setRegistration((prev) => ({ ...prev, accountName: data.account_name }))
      })
      
    } else {
      setRegistration((prev) => ({
        ...prev,
        [target.name]: target.value,
      }))
    }
  }

  // console.log("registration-->", registration)

  // setBankRefs
  useEffect(() => {
    setBankList(bankRefs)
  }, [])

  // mailer - registration
  const handleSubmit = async (evt) => {
    evt.preventDefault()
    setLoading(true)

    if (!registration.bankCode) return alert('Select Your Bank')

    if (
      verifyPhoneNumber(registration.contactPhone) ||
      verifyPhoneNumber(registration.pos_phone_number)
    ) {
      let pswrdHash = bcryptjs.hashSync(registration.password, 10)

      if (registration) {
        let _data = {}

        if (formView === 'other') {
          _data = {
            message: `
              <strong>Business Name:</strong> ${registration.name} <br />
              <strong>Email:</strong> ${registration.email} <br />  
              <strong>Account Name:</strong> ${registration.accountName} <br />
              <strong>Account Number:</strong> ${registration.accountNumber} <br />
              <strong>Bank Name:</strong> ${registration.bankName} <br />
              <strong>Bank Code:</strong> ${registration.bankCode} <br />
              <strong>Contact Name:</strong> ${registration.contactName} <br />
              <strong>Contact Phone:</strong> ${registration.contactPhone} <br />
              <strong>Callback URL:</strong> ${registration.callbackUrl || ''} <br />
              <strong>Validation Url:</strong> ${registration.validationUrl || ''} <br />
              <strong>Password:</strong> <code>${pswrdHash}</code> <br />
               <strong>Referral Code:</strong> <code>${
                 registration?.referral_code || null
               }</code> <br />
            `,
            receiver: 'nnorukaemeka@gmail.com, idepositafrica@gmail.com',
            subject: 'New School / One-off Merchant for Onboarding.',
            action: 'Confirm',
          }
        } else if (formView === 'pos') {
          _data = {
            message: `
              <strong>Business Name:</strong> ${registration.name} <br />
              <strong>Email:</strong> ${registration.pos_email} <br />  
              <strong>POS Number:</strong> ${registration.pos_phone_number} <br />
              <strong>Password:</strong> <code>${pswrdHash}</code> <br />
              <strong>Referral Code:</strong> <code>${
                registration?.referral_code || null
              }</code> <br />
            `,
            receiver: 'nnorukaemeka@gmail.com, idepositafrica@gmail.com',
            subject: 'New POS Merchant for Onboarding.',
            action: 'Confirm',
          }
        }
        // receiver: 'nnorukaemeka@gmail.com, idepositafrica@gmail.com',
        try {
          fetch(`${process.env.REACT_APP_MAILER_API}`, {
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(_data),
            mode: 'cors',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Credentials': true,
              'Access-Control-Allow-Methods': 'POST',
              'Content-Type': 'application/json, charset=UTF-8',
            },
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.status) {
                Swal.fire({
                  icon: 'success',
                  title: 'Successfuly submitted!',
                  showConfirmButton: true,
                  showCloseButton: false,
                }).then((result) => {
                  if (result.isConfirmed) navigate('/')
                })
              }

              setLoading(false)
            })
            .catch((err) => {
              setErr('Error submitting data...Please try again!')
              setLoading(false)

              Swal.fire({
                icon: 'error',
                text: 'Error submitting data...Please try again!',
                showConfirmButton: false,
                showCloseButton: true,
              })

              console.error('Error submitting data', err)
            })
        } catch (err) {
          console.log('Error submitting data', err)
          setErr('Error Submitting Info!')
          setLoading(false)
        }
      } else {
        setLoading(false)
        alert('Please fill in all Registration Form fields!')
      }
    } else {
      alert('Please enter valid Registration Phone Number!')
    }
  }

  const handleFormView = ({ target }) => {
    setFormView(target.value)
    setRegistration({})
  }

  return (
    <div>
      <div className='login-form signup-form'>
        <h4 className='login-title text-center'>REGISTER</h4>
        <h5 className='text-center'>Who are you registering as?</h5>
        <div style={form_type_select}>
          <div>
            <label htmlFor='other'>Organisation/Other</label>{' '}
            <input
              id='other'
              // inline
              // label='School/Other Merchant'
              name='formview'
              type='radio'
              value='other'
              onChange={handleFormView}
              checked={formView === 'other' && true}
            />
          </div>
          <div>
            <label htmlFor='pos'>POS Merchant</label>{' '}
            <input
              id='pos'
              // inline
              // label='POS Merchant'
              name='formview'
              type='radio'
              value='pos'
              onChange={handleFormView}
              checked={formView === 'pos' && true}
            />
          </div>
        </div>
        <div className='row'>
          <form id='contactForm' onSubmit={handleSubmit} className='log-form'>
            {/* business name */}

            {formView === 'other' && (
              <>
                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <p style={labelStyle}>It does not matter if your business is registered or not</p>
                  <input
                    onChange={changeFunc}
                    value={registration.name || ''}
                    name='name'
                    type='text'
                    id='name'
                    className='form-control'
                    placeholder='Business Name'
                    data-error='Please enter your name'
                  />
                </div>
                {/* business address */}
                {/* <div className='col-md-12 col-sm-12 col-xs-12'>
                  <p style={labelStyle}>
                    Tell us where your business is located. It is a CBN
                    requirement.
                  </p>
                  <input
                    onChange={changeFunc}
                    value={registration.address || ''}
                    name='address'
                    type='text'
                    id='address'
                    className='form-control'
                    placeholder='Business Address'
                    data-error='Please enter your business address'
                  />
                </div> */}

                {/* Email */}
                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <p style={labelStyle}>
                    You will also receive transaction notifications on this email address.
                  </p>
                  <input
                    onChange={changeFunc}
                    value={registration.email || ''}
                    name='email'
                    type='email'
                    id='email'
                    className='form-control'
                    placeholder='Email'
                    required
                    data-error='Please enter your email'
                  />
                </div>

                {/* Account Name */}
                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <p style={labelStyle}>
                    Transactions will be transferred to this account everyday
                  </p>
                  <select
                    name='bankName'
                    className='form-select form-input form-control'
                    onChange={changeFunc}
                    required
                  >
                    <option selected disabled>
                      --Select Bank--
                    </option>
                    {bankList?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Account Number */}
                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <input
                    onChange={changeFunc}
                    value={registration.accountNumber || ''}
                    name='accountNumber'
                    type='text'
                    id='accountNumber'
                    className='form-control'
                    placeholder='Account Number'
                    required
                    data-error='Please enter your name'
                    disabled={!registration?.bankCode ? true : false}
                  />
                </div>

                {/* Bank Name */}
                {/* <div className='col-md-12 col-sm-12 col-xs-12'>
                  <input
                    onChange={changeFunc}
                    value={registration.bankName || ''}
                    name='bankName'
                    type='text'
                    id='bankName'
                    className='form-control'
                    placeholder='Bank Name'
                    required
                    data-error='Please enter your name'
                  />
                </div> */}

                {/* <div className='col-md-12 col-sm-12 col-xs-12'>
                  <p style={labelStyle}>Select Bank</p>
                  <ReactHTMLDatalist
                    name='bankName'
                    onChange={changeFunc}
                    classNames='form-select form-input form-control'
                    styles={{
                      color: 'white',
                      padding: 5,
                      background: '#1b2654',
                      caretColor: 'white',
                    }}
                    options={bankList?.map((option) => {
                      return {
                        text: option.name,
                        value: option.id,
                      }
                    })}
                    placeholder='Select Bank'
                  />
                </div> */}

                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <input
                    onChange={changeFunc}
                    value={registration.accountName || ''}
                    name='accountName'
                    type='text'
                    id='accountName'
                    className='form-control'
                    placeholder='Account Name'
                    required
                    data-error='Please enter your name'
                    disabled
                  />
                </div>

                {/* Name of Contact Person */}
                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <p style={labelStyle}>This is the person that represents the business</p>
                  <input
                    onChange={changeFunc}
                    value={registration.contactName || ''}
                    name='contactName'
                    type='text'
                    className='form-control'
                    placeholder='Name of Contact Person'
                    data-error='Please enter your name'
                  />
                </div>
                {/* Name of Contact Phone */}
                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <p style={labelStyle}>This is your contacts phone</p>
                  <input
                    onChange={changeFunc}
                    value={registration.contactPhone || ''}
                    name='contactPhone'
                    type='text'
                    className='form-control'
                    placeholder='Contacts Phone Number'
                    data-error='Please enter contacts Phone Number'
                  />
                </div>
              </>
            )}

            {formView === 'pos' && (
              <>
                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <p style={labelStyle}>It does not matter if your business is registered or not</p>
                  <input
                    onChange={changeFunc}
                    value={registration.name || ''}
                    name='name'
                    type='text'
                    id='name'
                    className='form-control'
                    placeholder='Business Name'
                    data-error='Please enter your name'
                  />
                </div>
                {/* POS Phone Number */}
                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <p style={labelStyle}>
                    This phone number will receive notification of every successful transaction.
                  </p>
                  <input
                    onChange={changeFunc}
                    value={registration.pos_phone_number || ''}
                    name='pos_phone_number'
                    type='phone'
                    id='phone'
                    className='form-control'
                    placeholder='POS Phone Number'
                    required
                    data-error='Please enter your POS phone number'
                  />
                </div>

                {/* Email POS Merchant */}
                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <p style={labelStyle}>
                    You will also receive transaction notifications on this email address.
                  </p>
                  <input
                    onChange={changeFunc}
                    value={registration.pos_email || ''}
                    name='pos_email'
                    type='email'
                    id='email'
                    className='form-control'
                    placeholder='Email'
                    required
                    data-error='Please enter your pos email'
                  />
                </div>
              </>
            )}

            {/* Cashier/POS phone number */}
            {/* <div className='col-md-12 col-sm-12 col-xs-12'>
              <p style={labelStyle}>
                This is a phone number that can generate or verify payment
                references for your business
              </p>
              <input
                onChange={changeFunc}
                value={registration.cashierPhone || ''}
                name='cashierPhone'
                type='phone'
                id='cashierPhone'
                className='form-control'
                placeholder='Cashier/POS phone number'
                data-error='Please enter your cashier phone number'
              />
            </div> */}

            {formView && (
              <>
                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <input
                    type='password'
                    id='msg_subject'
                    name='password'
                    onChange={changeFunc}
                    value={registration.password || ''}
                    className='form-control'
                    placeholder='Password'
                    required
                    data-error='Please enter your password'
                  />
                </div>
              </>
            )}
            {formView === 'other' && (
              <>
                <div className='col-md-12 col-sm-12 col-xs-12 mb-5'>
                  <span>Do you have a website?</span>
                  <input
                    onChange={() => {
                      setHaveSite(!haveSite)
                    }}
                    type='checkbox'
                    className='check-box-input'
                    checked={haveSite}
                  />
                </div>
                {haveSite && (
                  <>
                    {/* Callback URL */}
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                      <p style={labelStyle}>
                        Where we shall automatically notify you of transaction status
                      </p>
                      <input
                        onChange={changeFunc}
                        value={registration.callbackUrl || ''}
                        name='callbackUrl'
                        type='text'
                        id='callbackUrl'
                        className='form-control'
                        placeholder='Callback URL'
                        data-error='Please enter your name'
                      />
                    </div>

                    {/* Validation URL */}
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                      <p style={labelStyle}>
                        This is important if you have a website for your business with database of
                        customers. This URL is where our system will automatically visit to validate
                        that your customers submitted valid identity such as school registration
                        number
                      </p>
                      <input
                        onChange={changeFunc}
                        value={registration.validationUrl || ''}
                        name='validationUrl'
                        type='url'
                        id='validationUrl'
                        className='form-control'
                        placeholder='Validation URL'
                        data-error='Please enter your validation url'
                      />
                    </div>
                  </>
                )}
              </>
            )}

            {/* <div className='col-md-12 col-sm-12 col-xs-12'>
              <input
                type='password'
                id='msg_subject'
                name='password'
                onChange={changeFunc}
                value={registration.password || ''}
                className='form-control'
                placeholder='Password'
                required
                data-error='Please enter your password'
              />
            </div>
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <input
                type='password'
                onChange={changeFunc}
                id='cmsg_subject'
                className='form-control'
                placeholder='Confirm Password'
                required
                data-error='Please enter your message subject'
                name='confPassword'
                value={registration.confPassword || ''}
              />
            </div> */}

            <div className='col-md-12 col-sm-12 col-xs-12' style={{ marginTop: 15 }}>
              <p style={labelStyle}>(Optional)</p>
              <input
                type='text'
                id='referral_code'
                className='form-control'
                placeholder='Referral Code'
                autoComplete='off'
                value={registration.referral_code || ''}
                name='referral_code'
                onChange={changeFunc}
              />
            </div>

            {formView && (
              <>
                <div className='col-md-12 col-sm-12 col-xs-12 text-center'>
                  <div className='check-group flexbox'>
                    <label className='check-box'>
                      <input
                        onChange={changeFunc}
                        type='checkbox'
                        className='check-box-input'
                        checked
                      />
                      <span className='remember-text'>I agree terms & conditions</span>
                    </label>
                    {message && <span>{message}</span>}
                  </div>
                </div>
                <div className='col-md-12 col-sm-12 col-xs-12 text-center'>
                  <button
                    disabled={disabled}
                    type='submit'
                    id='submit'
                    className='slide-btn login-btn'
                  >
                    Signup
                    <ClipLoader color='#ffffff' loading={loading} css={override} size={15} />
                  </button>
                  <p style={{ color: 'red' }}>{err}</p>
                  <p id='info' style={{ color: 'green' }}>
                    {info}
                  </p>
                </div>
              </>
            )}

            <div className='col-md-12 col-sm-12 col-xs-12 text-center'>
              <div className='separetor text-center'>
                <Link to='/'>Back to Home</Link>
              </div>
              <div className='sign-icon'>
                <div className='acc-not'>
                  Already have an account?{' '}
                  <a href='/login' style={{ textDecoration: 'underline' }}>
                    Login
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
