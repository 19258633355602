import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
// ---------------------------------------------------reducers
import authReducer from './slices/authSlice'
import paymentTypeReducer from './slices/paymentTypeSlice'
import merchantReducer from './slices/merchantSlice'
import transactionReducer from './slices/transactionSlice'

const reducers = combineReducers({
  auth: authReducer,
  paymentTypes: paymentTypeReducer,
  merchants: merchantReducer,
  transactions: transactionReducer
})

const persistConfig = {
  key: 'qsafepwerayy',
  storage,
  whitelist: ['auth'],
  // blacklist: ['merchants', 'paymentTypes', 'transactions'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
})
export const persistor = persistStore(store)
