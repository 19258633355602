import React, { useState } from 'react';
import { auth } from '../firebase';
import { signOut } from '@firebase/auth';
import { onAuthStateChanged } from '@firebase/auth';
import routes from '../routes';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
  const navigate = useNavigate()

  const logout = () => {
    sessionStorage.removeItem('x-bearer');
    localStorage.clear()
    navigate("/")
  };
  return (
    <div>
      <a href={routes.home} className='s-menu' onClick={logout}>
        Logout
      </a>
    </div>
  );
}
