import React, { useState, useEffect } from 'react';
import './style.css';
import { Container, Form, Button } from 'react-bootstrap';
import Avatar from 'react-avatar';

export const BusinessInfo = ({ info }) => {
  const [isRegistered, setIsRegistered] = useState('no');

  const handleRegistersUpload = ({ target }) => {
    setIsRegistered(target.value);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };


  return (
    <div>
      <Container fluid data-aos='fade-up'>
        <form className='business-form' onSubmit={handleSubmit}>
          <div className='left'>
            <Form.Group className='mb-3'>
              <Form.Label>Business Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter the name of your business'
                value={info.merchant_name || ''}
                disabled={true}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Business Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter the email of your business'
                value={info.merchant_email || ''}
                disabled={true}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Business Phone</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter the business phone number'
                value={info.merchant_phone || ''}
                disabled={true}
              />
            </Form.Group>
            {/* <Form.Group className='mb-3'>
              <Form.Label>Business Address</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                placeholder='Enter your bussiness address.'
              />
            </Form.Group> */}
          </div>
          <div className='right'>
            <Form.Group>
              <div className='question-radios'>
                <p>Is the Business registerd with C.A.C. </p>
                <Form.Check
                  inline
                  label='Yes'
                  name='isCac'
                  type='radio'
                  value='yes'
                  onChange={handleRegistersUpload}
                  checked={isRegistered === 'yes' && true}
                />
                <Form.Check
                  inline
                  label='No'
                  name='isCac'
                  type='radio'
                  value='no'
                  onChange={handleRegistersUpload}
                  checked={isRegistered === 'no' && true}
                />
              </div>
            </Form.Group>

            {isRegistered === 'no' && (
              <>
                <Form.Group controlId='formFile'>
                  <Form.Label>Upload Registration Document</Form.Label>
                  <Form.Control type='file' accept='image/png, image/jpeg' disabled={true} />
                </Form.Group>
              </>
            )}

            <Form.Group controlId='formFile'>
              <Form.Label>Upload Business Logo</Form.Label>
              <Form.Control type='file' accept='image/png, image/jpeg' disabled={true} />
            </Form.Group>

            <div className='avatar'>
              <Avatar size='100' round={true} />
            </div>
            <div className='business-submit'>
              <Button variant='primary' type='submit'>
                Save
              </Button>
            </div>
          </div>
        </form>
      </Container>
    </div>
  )
};
