import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './style.css'
import { Container, Button, Form } from 'react-bootstrap'
import formatDate from '../../../modules/formatDate.module'
import { Divider, Pagination, Table } from 'semantic-ui-react'
import { getTransactions, filterByChannels } from '../../../modules/services.module'
import Swal from 'sweetalert2'
import { SpinnerCircular } from 'spinners-react'
import { getAllTransactions, filterTransactionsByDate } from '../../../redux/slices/services'
import { useDispatch } from 'react-redux'

export const Transactions = () => {
  const {
    user: { merchant_id },
  } = useSelector((state) => state.auth)

  const { sortedByDate, dateSortLoading, dateSortNumOfPages } = useSelector(
    (state) => state.transactions
  )

  const dispatch = useDispatch()

  const [pagination, setPagination] = useState(1)
  const [sortRange, setSortRange] = useState({
    start_date: '2021-09-01 00:00:00',
    merchant_id: merchant_id,
  })

  const datetFilterMethod = ({ target }) => {
    setSortRange((prev) => ({ ...prev, [target.name]: `${target.value} 00:00:00` }))
  }


  useEffect(() => {
    dispatch(filterTransactionsByDate({ page_number: pagination, ...sortRange }))
  }, [pagination, sortRange])

  return (
    <div>
      <div className='container-fluid'>
        <div className='transaction-topBar mb-1'>
          <Form.Group className='mb-3' size='sm'>
            <Form.Label>From</Form.Label>
            <Form.Control
              type='date'
              placeholder='End Date'
              name='start_date'
              onChange={datetFilterMethod}
            />
          </Form.Group>
          <Form.Group className='mb-3' size='sm'>
            <Form.Label>To</Form.Label>
            <Form.Control
              type='date'
              placeholder='Start Date'
              name='end_date'
              onChange={datetFilterMethod}
            />
          </Form.Group>

          {/* <Form.Select
            style={{ height: 35, width: 'max(20vw)', color: 'black' }}
            onChange={channelFilterMethod}
          >
            <option value='SP-USSD'>SP-USSD</option>
            <option value='FD-USSD'>FD-USSD</option>
            <option value='VERGE-CARD'>VERGE-CARD</option>
            <option value='VERGE-USSD'>VERGE-USSD</option>
            <option value='VERGE-NQR'>VERGE-NQR</option>
            <option value='TRANSFER'>TRANSFER</option>
            <option value='SPWALLET'>SPWALLET</option>
          </Form.Select> */}
          {/* <Button variant='primary' disabled={true}>
            Download Report
          </Button> */}
        </div>

        <div>
          {dateSortLoading ? (
            <div className='text-center' data-aos='fade-up'>
              <SpinnerCircular size={30} color='#ffb24d' />
            </div>
          ) : (
            <>
              <Table data-aos='fade-up' celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Alert</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {sortedByDate.map((tranx, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>NGN{tranx.transaction_amount}</Table.Cell>
                      <Table.Cell>{tranx.description}</Table.Cell>
                      <Table.Cell>{tranx.payment_flow}</Table.Cell>
                      <Table.Cell>{formatDate(tranx.date)}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <div className='row text-right'>
                <Pagination
                  fluid
                  defaultActivePage={pagination}
                  totalPages={dateSortNumOfPages}
                  size='tiny'
                  color='yellow'
                  onPageChange={(event, data) => {
                    setPagination(data.activePage)
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
