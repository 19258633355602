import { useState } from 'react'
import bcryptjs from 'bcryptjs'
import { css } from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import jsonwebtoken from 'jsonwebtoken'
import { useDispatch } from 'react-redux'
import routes from '../routes'
import Swal from 'sweetalert2'
import { signIn } from '../redux/slices/authSlice'

const override = css`
  display: block;
  margin: 0 auto;
`

export default function Login() {
  const [loginDetails, setLoginDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  let navigate = useNavigate()
  const dispatch = useDispatch()

  // redux
  // const userDetails = useSelector((state) => state.isLoggedIn);
  // dispatch(setLoginStatus());
  const handleInputs = ({ target }) => {
    setLoginDetails((prev) => ({
      ...prev,
      [target.name]: target.value,
    }))
  }

  const handleLogin = (evt) => {
    evt.preventDefault()
    setLoading(true)

    try {
      fetch(`${process.env.REACT_APP_VALIDATE_USER_PASSWORD}${loginDetails.merchant}`, {
        method: 'GET',
        credentials: 'same-origin',
        // body: JSON.stringify(_data),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false)
          if (data.status) {
            bcryptjs.compareSync(loginDetails.password, data.data)
              ? genToken(loginDetails.merchant)
              : setMessage('Invalid credentials. Call 0700-SAFEPAY for assistance.')
          } else {
            setMessage(data.message)
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            text: err.message,
            showCloseButton: true,
            showConfirmButton: false
          })
          setLoading(false)
        })
    } catch (error) {
      // console.log(error);
    }
  }

  const genToken = (id) => {
    setLoading(true)
    try {
      fetch(`${process.env.REACT_APP_MERCHANT_DETAILS}${id}`)
        .then((res) => res.json())
        .then((data) => {
          setLoading(false)
          if (data.status) {
            const { merchant_id, merchant_phone } = data.data

            const token = jsonwebtoken.sign(
              { phone_number: merchant_phone, user_id: merchant_id },
              `${process.env.REACT_APP_JWT_SECRET}`,
              {
                expiresIn: '1h',
              }
            )

            sessionStorage.setItem('x-bearer', token)
            setUserState(id)
          } else {
            // console.log(data.message)
            Swal.fire({
              icon: 'error',
              text: data.message,
              showCloseButton: true,
              showConfirmButton: false,
            })
          }
        })
        .catch((err) => {
          setLoading(false)
          Swal.fire({
            icon: 'error',
            text: err.message,
            showCloseButton: true,
            showConfirmButton: false,
          })
        })
    } catch (err) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        text: err.message,
        showCloseButton: true,
        showConfirmButton: false,
      })
    }
  }

  const setUserState = (id) => {
    setLoading(true)
    try {
      fetch(`${process.env.REACT_APP_MERCHANT_DETAILS}${id}`)
        .then((res) => res.json())
        .then((data) => {
          const userDataArr = Object.entries(data.data)
          const filteredArr = userDataArr.filter(function ([key, value]) {
            return (
              key !== 'password' &&
              key !== 'POS_phone' &&
              key !== 'business_category' &&
              key !== 'activation' &&
              key !== 'create_time' &&
              key !== 'last_update' &&
              key !== 'billing_category' &&
              key !== 'settlement_category' &&
              key !== '001' &&
              key !== '07039374656' &&
              key !== '08034335775' &&
              key !== 'validation_api' &&
              key !== 'callback_url'
            )
          })
          const cleanData = Object.fromEntries(filteredArr)

          if (data.status) {
            dispatch(signIn(cleanData))
            navigate(routes.dashboard, { replace: true })
            window.location.reload()
          }
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          Swal.fire({
            icon: 'error',
            text: 'Network Err',
            showCloseButton: true,
            showConfirmButton: false,
          })
        })
    } catch (err) {
      console.log(`Error fetching data: Offline`)
      Swal.fire({
        icon: 'error',
        text: "Network Err",
        showCloseButton: true,
        showConfirmButton: false,
      })
      setLoading(false)
    }
  }

  return (
    <>
      <div className='login-area area-padding fix'>
        <div className='login-overlay'></div>
        <div className='table'>
          <div className='table-cell'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8 col-xs-12'>
                  <div className='login-form'>
                    <h4 className='login-title text-center'>LOGIN</h4>
                    <div className='row'>
                      <form id='contactForm' className='log-form' onSubmit={handleLogin}>
                        <div className='col-md-12 col-sm-12 col-xs-12'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='MerchantID / Phone Number'
                            required
                            data-error='Please enter Merchant ID / Phone Number'
                            autoComplete='off'
                            value={loginDetails.merchant || ''}
                            name='merchant'
                            onChange={handleInputs}
                          />
                        </div>

                        <div className='col-md-12 col-sm-12 col-xs-12'>
                          <input
                            type='password'
                            id='password'
                            className='form-control'
                            placeholder='Password'
                            required
                            data-error='Please enter your password'
                            autoComplete='off'
                            value={loginDetails.password || ''}
                            name='password'
                            onChange={handleInputs}
                          />
                        </div>

                        <div className='col-md-12 col-sm-12 col-xs-12 text-center'>
                          <button type='submit' id='login-button' className='slide-btn login-btn'>
                            {!loading ? (
                              'Login'
                            ) : (
                              <ClipLoader
                                color='#ffffff'
                                loading={loading}
                                css={override}
                                size={20}
                              />
                            )}
                          </button>
                          <div id='msgSubmit' className='h3 text-center hidden'></div>
                          <div className='clearfix'></div>
                        </div>
                        <div className='col-md-12 col-sm-12 col-xs-12 text-center'>
                          <div className='clear'></div>
                          <div className='separetor text-center'>
                            <Link to='/'>Back to Home</Link>
                          </div>
                          <div className='sign-icon'>
                            {message && <small>{message}</small>}
                            <div className='acc-not'>
                              Don't have an account?{' '}
                              <Link to='/signup' style={{ textDecoration: 'underline' }}>
                                Sign Up
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
