import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { getDatasetAtEvent, Line } from 'react-chartjs-2';

export const Overview = () => {
  const [chartData, setChartData] = useState([
    {
      alert: 'Credit',
      amount: 800,
      date: 2,
    },
    {
      alert: 'Debit',
      amount: 320,
      date: 6,
    },
    {
      alert: 'Debit',
      amount: 320,
      date: 6,
    },
    {
      alert: 'Debit',
      amount: 750,
      date: 8,
    },
    {
      alert: 'Debit',
      amount: 420,
      date: 3,
    },
    {
      alert: 'Credit',
      amount: 1350,
      date: 8,
    },
    {
      alert: 'Credit',
      amount: 670,
      date: 1,
    },
  ]);

  // useEffect(() => {
  //   try {
  //     fetch(
  //       'https://safe-payy.herokuapp.com/coralpay/pos/querytransaction/phone/001'
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         data.status && console.log(data.data.transactions);
  //         let overview_data = data.data.transactions.map((trax) => ({
  //           alert: trax.alert,
  //           amount: parseFloat(trax.amount.replace(/[^.0-9 ]/g, '')),
  //           date: new Date(trax.date.replace('at', '')).getMonth(),
  //         }));
  //         // console.log(overview_data);
  //         setChartData(overview_data);
  //       });
  //   } catch (err) {
  //     console.log(`Error fetching data: Offline`);
  //   }
  // }, []);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Transactions Overview',
      },
    },
  };

  // const labels = [
  //   'January',
  //   'February',
  //   'March',
  //   'April',
  //   'May',
  //   'June',
  //   'July',
  //   'August',
  //   'September',
  //   'October',
  //   'November',
  //   'December',
  // ];

  // const avMonths =
  //   chartData &&
  //   labels.filter((trans) => labels[chartData.map((x) => x.date)] === trans);

  // const avMonths =
  //   chartData &&
  //   labels.filter((month) => {
  //     let date = chartData.map((x) => x.date);
  //     // labels[] === trans
  //     // return labels[1] === month;
  //     let newArr = [];
  //     date.forEach((el) => {
  //       // labels[el] === month && newArr.push(labels[el]);
  //       newArr.push(labels[el]);
  //     });
  //     return newArr;
  //   });

  // let [date] = chartData && chartData.map((data) => data.date);
  // console.log(date);

  // console.log(chartData);

  const creditScore =
    chartData && chartData.filter((trans) => trans.alert === 'Credit');

  const debitScore =
    chartData && chartData.filter((trans) => trans.alert === 'Debit').sort((a, b) => {
      return a.date - b.date
    });

  // console.log(debitScore);

  let date = chartData && chartData.map((x) => x.date);
  // console.log(date);

  const handleMonth = (m) => {
    return {
      0: 'January',
      1: 'Febuary',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December',
    }[m];
  };

  let labels = [];

  date &&
    date.forEach((el) => {
      labels.push(handleMonth(el));
    });

  // console.log(labels);

  // sort months
  var monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const data = {
    labels: [...new Set(labels)].sort((a, b) => {
      return monthNames.indexOf(a) - monthNames.indexOf(b);
    }),
    datasets: [
      {
        label: 'Debit',
        data:
          debitScore &&
          debitScore.map(
            (dbt) => dbt.amount
            // faker.datatype.number({ min: -1000, max: 1000 })
          ),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Credit',
        data:
          creditScore &&
          creditScore.map(
            (cr) => cr.amount
            // faker.datatype.number({ min: -1000, max: 1000 })
          ),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return (
    <div>
      <Container fluid>
        <Line options={options} data={data} />
      </Container>
    </div>
  );
};
