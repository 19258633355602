import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Homepage from './pages/Homepage'
import Signup from './pages/Signup'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Contact from './pages/Contact'
import WebTransaction from './pages/WebTransaction'
import Admin from './pages/Admin'
// import { auth } from './firebase';
// import { onAuthStateChanged } from '@firebase/auth';
import routes from './routes'
import jsonwebtoken from 'jsonwebtoken'
import { Navigate } from 'react-router-dom'
import TransConfirmation from './pages/TransConfirmation'

function App() {
  // const [user, setUser] = useState()

  // useEffect(() => {
  //   sessionStorage.getItem('x-bearer') &&
  //     setUser(jsonwebtoken.decode(sessionStorage.getItem('x-bearer')))
  // }, [])

  // console.log( sessionStorage.getItem('x-bearer'))
  return (
    <Router>
      <Routes>
        <Route index element={<Homepage />} />

        {/* {user && user.user_id === '001' && <Route path={routes.admin} element={<Admin />} />} */}

        <Route
          path={routes.signup}
          element={
            sessionStorage.getItem('x-bearer') != null ? (
              <Navigate to={routes.dashboard} />
            ) : (
              <Signup />
            )
          }
        />

        {/* /payment/confirmation */}

        <Route path={routes.login} element={<Login />} />

        <Route path={routes.contact} element={<Contact />} />

        {sessionStorage.getItem('x-bearer') != null && (
          <Route path={routes.dashboard} element={<Dashboard />} />
        )}

        {/* payment reference */}
        <Route path='/payment/:payment_ref' element={<WebTransaction />} />
        <Route
          path='/payment/confirmation'
          element={<TransConfirmation />}
        />

        <Route path='*' element={<Navigate to={routes.home} />} />
      </Routes>
    </Router>
  )
}

export default App
