import React, { useState } from 'react';
import './style.css';
import { Container, Form, Button } from 'react-bootstrap';
import bcryptjs from 'bcryptjs';

export const PersonalInfo = ({ info }) => {
  const [password, setPassword] = useState({});
  const [message, setMessage] = useState('');

  const handleData = ({ target }) => {
    setPassword((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const _data = password;

    if (_data.new_password === _data.confirm_password) {
      const passHash = bcryptjs.hashSync(password.confirm_password, 10);

      try {
        fetch(
          `${process.env.REACT_APP_UPDATE_PASSWORD}${info.merchant_phone}/${passHash}`,
          {
            method: 'GET',
            credentials: 'same-origin',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            data.status ? setMessage(data.message) : setMessage(data.message);
          })
          .catch((err) => {
            setMessage('Error...');
            // setLoading(false);
          });
      } catch (error) {
        // console.log(error);
      }
    } else {
      setMessage('Password does not match!');
    }
  };

  return (
    <div>
      <Container fluid>
        <form className='personal-form' onSubmit={handleSubmit} data-aos='fade-up'>
          <div className='form-row'>
            {/* <Form.Group className='mb-3'>
              <Form.Label>Firstname</Form.Label>
              <Form.Control type='text' placeholder='John' disabled={true} />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Lastname</Form.Label>
              <Form.Control type='text' placeholder='Doe' disabled={true} />
            </Form.Group> */}
          </div>
          <div className='form-row'>
            <Form.Group className='mb-3'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='johndoe@example.com'
                value={info.merchant_email || ''}
                disabled={true}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter you phone number'
                value={info.merchant_phone || ''}
                disabled={true}
              />
            </Form.Group>
          </div>
          <div className='form-row'>
            <Form.Group className='mb-3'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='*********'
                name='new_password'
                onChange={handleData}
                disabled={false}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='*********'
                name='confirm_password'
                onChange={handleData}
                disabled={false}
              />
            </Form.Group>
          </div>
          <p>{message}</p>
          <div className='submit'>
            <Button
              variant='primary'
              type='submit'
              disabled={password.confirm_password ? false : true}
            >
              Update
            </Button>
          </div>
        </form>
      </Container>
    </div>
  )
};
