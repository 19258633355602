import axios from 'axios'
axios.defaults.headers.common['x-access-token'] = process.env.REACT_APP_API_TOKEN

export const initialize_transaction = async (paymentref) => {
  const res = await axios.post(
    `${process.env.REACT_APP_COMPLETE_TRANSACTION}`,
    { paymentref },
    {
      headers: { 'content-type': 'application/json' },
    }
  )
  if (!res.data.status) {
    return res.data
  } else {
    window.location.replace(res.data.data.PayPageLink)
    return null
  }
}

export const confirm_transaction = async (id) => {
  const res = await axios.get(`${process.env.REACT_APP_FINALIZE_TRANSACTION}/${id}`, {
    headers: { 'content-type': 'application/json' },
  })
  return res.data
}
